@import '../../../styles/shared-imports';

@keyframes fadeIn {
  from {
    opacity: 0;
  }
}

.remainingTokensHeader {
  position: absolute;
  top: 13vh;
  left: 50%;
  transform: translateX(-50%);
  font-weight: 400;
  font-size: rem(18);
  line-height: 177%;
  letter-spacing: -0.03em;
  margin: 0;
  cursor: pointer;
  pointer-events: auto;
  z-index: 10;
  color: rgba(255, 255, 255, 0.75);
  padding: 0;
  outline: none;
  appearance: none;
  border: none;
  background: none;
  font-family: inherit;
  transition: color 300ms ease;
  white-space: nowrap;

  @include above('medium') {
    display: none;
  }

  @include above('desktop') {
    right: 4.5vh;
    bottom: 4.5vh;
    transform: none;
    top: unset;
    left: unset;
    display: initial;
  }

  &:after {
    content: '';
    width: 100%;
    height: 1px;
    display: block;
    background: white;
    transition: transform 300ms;
    transform: scaleX(0);
    transform-origin: left center;
  }

  &:hover {
    color: white;

    &:after {
      transform: scaleX(1);
    }
  }

  &:disabled {
    color: rgba(255, 255, 255, 0.75);
    pointer-events: none;
    cursor: auto;

    &:after {
      transform: scaleX(0);
    }
  }
}

.toolbar {
  position: absolute;
  bottom: $padding;
  left: 50%;
  transform: translateX(-50%);
  pointer-events: auto;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;

  @media (orientation: portrait) {
    bottom: 10vh;
  }

  animation: fadeIn 1s 1 ease-out backwards 0.5s;
}

.buttonIcon {
  height: rem(15);
  margin-right: rem(6);
  position: relative;
  top: 0.1em;
  width: rem(15);
}
