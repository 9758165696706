@import '../../../../styles/shared-imports';

.link {
  white-space: nowrap;
}

.content {
  overflow: auto;
  padding-top: 7vh;
}

.mediumBody {
  margin: rem(30) 0 0;
  font-size: rem(24);
  line-height: 1.5;

  @include above('desktop') {
    letter-spacing: -0.03em;
  }
}

.smallBody {
  font-size: rem(16);
  line-height: 1.4;
  opacity: 0.7;

  @include above('desktop') {
    @include max-prop(font-size, rem(14), vw(14));
    line-height: 1.5;
  }
}
