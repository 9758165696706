@import '../../../styles/shared-imports';

@keyframes fadeIn {
  from {
    opacity: 0;
  }
}

@keyframes fadeInOutTransition {
  0% {
    opacity: 0;
  }
  1.5%,
  98.5% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.minting {
  animation: fadeIn 0.3s 1 ease-out backwards 0.5s;
  bottom: 2.5vh;
  color: var(--color-white);
  display: inline-block;
  font-size: rem(12);
  left: 50%;
  line-height: 1.5;
  position: fixed;
  text-align: center;
  transform: translateX(-50%);
  width: 100%;

  &.fadeOut {
    opacity: 0;
    transition: opacity 0.4s ease;
  }

  &.fadeInOut {
    animation: fadeInOutTransition 10s infinite;
  }

  @include above('desktop') {
    bottom: 4.5vh;
    font-size: rem(14);
  }
}

@keyframes bounce {
  0% {
    opacity: 0;
    transform: translate(-50%, 7vh);
  }

  60% {
    opacity: 1;
    transform: translate(-50%, -10px);
  }

  80% {
    transform: translate(-50%, 3px);
  }
  100% {
    transform: translate(-50%, 0);
  }
}

.error {
  align-items: center;
  animation: bounce 850ms var(--ease-back-out);
  background-color: var(--color-white);
  border-radius: rem(8);
  bottom: 2.5vh;
  color: var(--color-black);
  display: flex;
  gap: rem(9);
  justify-content: center;
  padding: rem(5.5) rem(8);
  width: unset;

  @include above('desktop') {
    bottom: 4.5vh;
    padding: rem(9) rem(16);
  }
}

.icon {
  height: rem(14);
  width: rem(14);

  @include above('desktop') {
    height: rem(18);
    width: rem(18);
  }
}

.text {
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
}
