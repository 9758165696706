@import '../../../styles/shared-imports';

.wrapper {
  position: absolute;
  bottom: $padding;
  right: $padding;
  width: 14vw;
  height: 1px;
  background: rgba(255, 255, 255, 0.2);
  overflow: hidden;
}

.progress {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background: #fff;
  transform-origin: left center;
  transform: scaleX(0);
}
