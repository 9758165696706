// IMPORTANT
// This file is here so css vars are only declared once instead of everytime a shared import is used.

@import '../config/easing';
@import '../config/breakpoints';
@import '../config/colors';

// export breakpoints as CSS vars
:root {
  @each $key, $value in $breakpoints {
    --breakpoint-#{$key}: #{$value};
  }
}

// export colors as CSS vars
:root {
  @each $key, $value in $colors {
    --color-#{$key}: #{$value};
  }
}
