@import '../../../styles/shared-imports';

.buttonCta {
  display: inline-flex;
  justify-content: center;
  align-items: center;

  & > * {
    margin: 0 rem(4.6);
  }
}
