@import './styles/base/css-vars';
@import './styles/shared-imports.scss';

#root {
  height: 100%;
}

.App {
  height: 100%;
}

@keyframes fadeOutIn {
  0% {
    opacity: 1;
  }
  30% {
    opacity: 0;
  }
  70% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.AppCanvasWrapper {
  height: 100%;
  left: 50%;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;

  &,
  div {
    align-items: center;
    display: flex;
    justify-content: center;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
}

.GUI {
  animation: fadeIn 1s 1 ease-out backwards 0.5s;
  @media (orientation: landscape), (min-width: 600px) {
    bottom: $padding !important;
  }

  @media (orientation: landscape), (min-width: 1000px) {
    bottom: calc(70px + #{$padding}) !important;
  }

  button {
    cursor: pointer;
    &:hover {
      background-color: #434343;
    }
  }
}

.AppUI {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  opacity: 0;
}
.loaded .AppUI {
  opacity: 1;
  animation: fadeIn 2s 1 ease-out 2s backwards;
  transition: opacity 200ms ease-out;
}
.AppUI.hidden {
  opacity: 0;
}

.btnNext,
.btnPrev {
  position: absolute;
  top: 50%;
  appearance: none;
  background: transparent;
  border: none;
  width: 14vw;
  height: 80vh;
  margin-top: -40vh;
  outline: none;
  box-shadow: none;
  -webkit-tap-highlight-color: transparent;
}
.btnNext {
  right: 0;
}
.btnPrev {
  left: 0;
}

.AboutFaqLink {
  padding: 0;
  position: absolute;
  top: $padding;
  pointer-events: auto;
  outline: none;
  appearance: none;
  border: none;
  background: none;
  font-size: rem(18);
  right: $padding;
  color: white;
  cursor: pointer;
  font-family: 'Aften Screen', sans-serif;
}
@media (any-hover: hover) {
  .AboutFaqLink:after {
    content: '';
    width: 100%;
    height: 1px;
    display: block;
    background: white;
    transition: transform 300ms;
    transform: scaleX(0);
    transform-origin: left center;
  }
  .AboutFaqLink:hover::after {
    transform: scaleX(1);
  }
}

a.NFTCollectionLink {
  position: absolute;
  bottom: $padding;
  left: $padding;
  font-size: rem(18);
  font-style: normal;
  font-weight: 300;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: white;
  text-decoration: none;
  pointer-events: auto;
  margin-bottom: -0.5em;
  transform: translateY(-50%);

  .remix & {
    @media (orientation: portrait) {
      display: none;
    }
  }
}

.NFTCollectionLink.hideOnMobile {
  display: none;

  @include above('desktop') {
    display: initial;
  }
}

.Gallery {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: auto;
}

h1,
h2,
h3,
h4 {
  font-weight: 400;
}
