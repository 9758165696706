@import '../../../styles/shared-imports';

.button {
  appearance: none;
  border-radius: rem(50);
  border: none;
  box-sizing: border-box;
  cursor: pointer;
  font-family: 'Aften Screen', sans-serif;
  font-size: rem(18);
  font-style: normal;
  letter-spacing: -0.03em;
  line-height: 177%;
  outline: none;
  padding: rem(9) rem(24);
  text-align: center;
  transition: background-color 0.2s ease-out, color 0.2s ease-out;
  white-space: nowrap;

  & + .button {
    margin-left: rem(15);
  }

  &:disabled {
    cursor: unset;
    background-color: var(--color-light-gray);
    color: var(--color-gray);
  }

  @include above('desktop') {
    &:hover:not(:disabled) {
      background-color: var(--color-dark-gray);
      color: var(--color-white);
    }
    padding: rem(9) rem(40);
  }
}

.primary {
  background-color: var(--color-white);
  color: var(--color-black);
}

.secondary {
  background-color: var(--color-black);
  color: var(--color-white);
}
